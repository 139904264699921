// Overrides for Pagination, Breadcrumbs


//
// > BREADCRUMB
//
.breadcrumb {
  background-color: $breadcrumb-bg;
}
// >> Breadcrumb Items
.breadcrumb-item {
  a {
    padding: 1px 0;
    color: $breadcrumb-color;

    &:hover {
      text-decoration: none;
      border-bottom: 2px solid $azurite;
    }
  }
}


//
// > PAGINATION
//
.page-link {
  color: $pagination-color;

  &:hover {
    color: $pagination-hover-color;
  }
}
